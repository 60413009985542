import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import apiService from '../../services/ApiService';
import SpinnerOverlay from '../helpers/SpinnerOverlay';

const BannerForm = ({ bannerToEdit }) => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [contentImage, setContentImage] = useState(null);
  const [brandId, setBrandId] = useState('');
  const [type, setType] = useState('');
  const [link, setLink] = useState('');
  const [sort, setSort] = useState('');
  const [visible, setVisible] = useState(null);
  const [loading, setBlogLoading] = useState(false);

  // Ako je bannerToEdit postavljen, popunjavamo formu sa podacima za edit
  useEffect(() => {
    if (bannerToEdit) {
      setContentImage(bannerToEdit.contentImage);
      setBrandId(bannerToEdit.brandId);
      setType(bannerToEdit.type);
      setLink(bannerToEdit.link);
      setSort(bannerToEdit.sort);
      setVisible(bannerToEdit.visible)
    }
  }, [bannerToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validacija forme
    if (!contentImage || !brandId || !type || !sort || isNaN(sort)) {
      toast.warn('Sva polja su obavezna i "sort" mora biti broj!');
      return;
    }

    const formData = new FormData();
    formData.append("contentImage", contentImage)
    formData.append("brandId", brandId)
    formData.append("type", type)
    formData.append("link", link)
    formData.append("sort", parseInt(sort))
    formData.append("visible", visible == null ? false : visible)

    if (bannerToEdit) {
      updateBanner(bannerToEdit._id, formData);
    } else {
      createBanner(formData);
    }
  };

  const createBanner = async (newBanner) => {
    setBlogLoading(true);
    const [success, error] = await apiService.post("banners", newBanner);

    setBlogLoading(false);

    if(error) {
        toast.error("Greska pri cuvanju bannera!");
    }

    if(success) {
      toast.success("Baner uspesno sacuvan!");
      // Reset forme
      setContentImage(null);
      setBrandId('');
      setType('');
      setLink('');
      setSort('');
      navigate('/banner/view')
    }
  }

  const updateBanner = async (id, newBanner) => {
    setBlogLoading(true);
    const [success, error] = await apiService.put("banners/" + id, newBanner);

    setBlogLoading(false);
    if(error) {
      toast.error(error.response.data.error.message ? error.response.data.error.message : "Greska pri cuvanju bannera!");
    }

    if(success) {
      toast.success("Baner uspesno izmenjen!");
      // Reset forme
      setContentImage(null);
      setBrandId('');
      setType('');
      setLink('');
      setSort('');
      navigate('/banner/view')
    }
  }

  const handleImageChange = (e) => {
    setContentImage(e.target.files[0]);
  };

  const getBrands = async() => {
    const [success, error] = await apiService.get("brands");

    if(error) {
        toast.error("Greska pri preuzimanju brendova!");
    }

    setBrands(success.data)
    if(success.data[0] != null) {
      setBrandId(success.data[0]._id)
    }
  }

  useEffect(() => {
    getBrands();
  }, [])

  return (
    <div className="container mt-5">
      <h2>{bannerToEdit ? 'Izmeni Banner' : 'Dodaj Banner'}</h2>
      <form onSubmit={handleSubmit}>
        {/* Slika */}
        <div className="mb-3">
          <label htmlFor="contentImage" className="form-label">Slika</label>
          <input
            type="file"
            className="form-control"
            id="contentImage"
            accept="image/*"
            onChange={handleImageChange}
            required={!bannerToEdit}
          />
        </div>

      {/* Brand ID - Disabled Dropdown */}
      <div className="mb-3">
        <label htmlFor="brandId" className="form-label">Brand ID</label>
        <select
          className="form-select"
          id="brandId"
          value={brandId}
          onChange={(e) => setBrandId(e.target.value)}
          disabled
        >
          {brands.map((brand, index) => (
            <option key={index} value={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>
      </div>

        {/* Tip Bannera */}
        <div className="mb-3">
          <label htmlFor="type" className="form-label">Tip Bannera</label>
          <select
            className="form-select"
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option value="" disabled>Odaberite tip</option>
            <option value="title">Title</option>
            <option value="body">Body</option>
          </select>
        </div>

        {/* Link */}
        <div className="mb-3">
          <label htmlFor="link" className="form-label">Link</label>
          <input
            type="text"
            className="form-control"
            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>

        {/* Sort */}
        <div className="mb-3">
          <label htmlFor="sort" className="form-label">Sort</label>
          <input
            type="number"
            className="form-control"
            id="sort"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            required
            min="1"
            max="6"
          />
        </div>

        {/* visibility */}
        {bannerToEdit &&
              <div className="form-check mb-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="visible"
                checked={visible}
                onChange={(e) => setVisible(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="visibleCheckbox">
                Visible
              </label>
            </div>
        }

        {/* Submit button */}
        <button type="submit" className="btn btn-primary">
          {bannerToEdit ? 'Ažuriraj Banner' : 'Dodaj Banner'}
        </button>
      </form>
      <SpinnerOverlay isLoading={loading} />
    </div>
  );
};

export default BannerForm;