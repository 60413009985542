import React, { useEffect, useState } from 'react';
import ViewActivityBanner from '../../components/Activity/ViewActivityBanners';
import { useNavigate } from 'react-router';
import apiService from '../../services/ApiService';
import { toast } from 'react-toastify';


const ActivityPage = () => {
    const navigate = useNavigate();

    const [activityPages, setActivityPages] = useState();
  
    const getActivityBanners = async() => {
      const [success, error] = await apiService.get("activity")
  
      if (error) {
        toast.error("Greska pri dohvatanju banera!");
        return;
      }
  
      setActivityPages(success.data);
    }

    const handleToggleAllVisibility = async (id, visibility) => {
        const [success, error] = await apiService.put("activity/"+id, {visibility: visibility})
    
        if (error) {
          toast.error("Greska pri cuvanju izmeni banera!");
          return;
        }
    
        const pages = activityPages.map(e =>  {
            if(e._id == id) {
                e.visibility = !e.visibility
            }

            return e;
        });
        setActivityPages(pages)
    };

    const handleDelete = async (id) => {
        const [success, error] = await apiService.delete("activity/"+id)
    
        if (error) {
          toast.error("Greska pri cuvanju brisanju banera!");
          return;
        }
    
        const pages = activityPages.filter(e => e._id != id);
        setActivityPages(pages)
    };
  
    useEffect(() => {
        getActivityBanners();
    },[])

    return (
        <div className="App">
            <h2 className="mt-5">Preview</h2>
            {activityPages != null && activityPages.length > 0 && activityPages?.map((page) => (
                <ViewActivityBanner
                    visibility={page.visibility}
                    id={page._id}
                    mainBanner={page.mainBanner}
                    banners={page.banners}
                    onDelete={handleDelete} 
                    onToggleVisibility={handleToggleAllVisibility}
                />
            ))}
        </div>
    );
};

export default ActivityPage;
