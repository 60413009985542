import React, { useState } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const ViewActivityBanner = ({ visibility, id, mainBanner, banners, onDelete, onToggleVisibility }) => {
    const navigate = useNavigate();

    return (
        <div className="banner-preview m-5">
            <h2>Main Banner Preview</h2>
            <Row className="mb-4">
                <Col md={4}>
                    <Card>
                        <Card.Img
                            variant="top"
                            crossOrigin="anonymous"
                            src={mainBanner.contentImage ? process.env.REACT_APP_CONTENT_URL + mainBanner.contentImage : 'placeholder.jpg'}
                            alt="Main Banner"
                        />
                        <Card.Body>
                            <Card.Title>{mainBanner.type}</Card.Title>
                            <Card.Text>Brand: {mainBanner.brand}</Card.Text>
                            <Card.Text>Link: {mainBanner.link}</Card.Text>
                            <Card.Text>Sort: {mainBanner.sort}</Card.Text>
                            {/* <Card.Text>Visible: {mainBanner.visibility ? 'Yes' : 'No'}</Card.Text> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <h2>Body Banners Preview</h2>
            <Row>
                {banners?.map((banner, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card>
                            <Card.Img
                                variant="top"
                                crossOrigin="anonymous"
                                src={
                                    banner.contentImage
                                        ? process.env.REACT_APP_CONTENT_URL + banner.contentImage
                                        : 'placeholder.jpg'
                                }
                                alt={`Body Banner ${index + 1}`}
                            />
                            <Card.Body>
                                <Card.Title>{banner.type}</Card.Title>
                                <Card.Text>Brand: {banner.brand}</Card.Text>
                                <Card.Text>Link: {banner.link}</Card.Text>
                                <Card.Text>Sort: {banner.sort}</Card.Text>
                                <Card.Text>Visible: {banner.visible ? 'Yes' : 'No'}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* Toggle switch for changing visibility of all body banners */}
            <Form.Group as={Row} controlId="toggleAllVisibility" className="mt-3 align-items-center">
                <Form.Label column sm="auto" className="mr-2">Toggle Visibility:</Form.Label>
                <Col sm="auto">
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label={visibility ? 'Visible' : 'Not Visible'}
                        checked={visibility}
                        onChange={() => { onToggleVisibility(id, !visibility); }}
                    />
                </Col>
            </Form.Group>

            {/* Button to delete all body banners */}
            <Button variant="danger" onClick={() => onDelete(id)} className="mt-3 m-2">
                Delete
            </Button>
        </div>
    );
};

export default ViewActivityBanner;
